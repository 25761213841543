<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-06-16 14:35:40
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-13 17:52:51
-->
<template>
  <a-card :border="false">
    <div class="realtor">
      <a-row>
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
          <a-row :gutter="2">
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="商品名称">
                <a-input v-model.trim="searchData.name" placeholder="请输入商品名称" :max-length="50" allowClear></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="分类">
                <a-select placeholder="请选择分类" v-model="searchData.categoryId" allowClear>
                  <a-select-option :value="item.id" v-for="(item, index) in categoryList" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="品牌">
                <a-select placeholder="请选择品牌" v-model="searchData.brandId" allowClear>
                  <a-select-option :value="item.id" v-for="(item, index) in brandList" :key="index">
                    {{ item.chName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12" v-if="pageType == 'all'">
              <a-form-model-item label="审核状态">
                <DictSelect
                  field="productStatus"
                  :value.sync="searchData.flowStatus"
                  style="width: 100%"
                  placeholder="审核状态"
                ></DictSelect>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12" v-if="pageType == 'audited'">
              <a-form-model-item label="审核状态">
                <DictSelect
                  field="auditedProductStatus"
                  :value.sync="searchData.flowStatus"
                  style="width: 100%"
                  placeholder="审核状态"
                ></DictSelect>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="6" :md="8" :sm="12" v-if="pageType != 'putaway' || pageType != 'off_shelf'">
              <a-form-model-item label="上架状态">
                <DictSelect
                  field="groundingStatus"
                  :value.sync="searchData.flagUp"
                  style="width: 100%"
                  placeholder="状态"
                ></DictSelect>
              </a-form-model-item>
            </a-col>
            <!-- <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item label="库存">
                <a-input v-model.trim="searchData.stock" placeholder="请输入库存" :max-length="50"></a-input>
              </a-form-model-item>
            </a-col> -->
            <a-col :xxl="6" :xl="6" :md="8" :sm="12">
              <a-form-model-item :labelCol="{ span: 0 }" :wrapperCol="{ span: 22, offset: 1 }">
                <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                <a-button type="default" @click="reset()" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div v-if="pageType == 'all' || pageType == 'untreated'">
          <a-button type="primary" @click="toHandler('check')" icon="copy" v-if="isCheck">查看</a-button>
          <a-button
            v-if="
              isSubmit && selectedRowKeys.length == 0 || (selectedRows.length > 0 && selectedRows[0].flowStatus == 1 )
            "
            type="primary"
            @click="onAudit('sbm_audit')"
            icon="form"
            >提交审核</a-button
          >
          <a-button
            v-if="isAudit && selectedRows.length > 0 && selectedRows[0].flowStatus == 2"
            type="primary"
            @click="visible = true"
            icon="form"
            >审核</a-button
          >
          <a-button type="primary" @click="toHandler('add')" icon="plus" v-if="isAdd">新增</a-button>
          <a-button type="primary" @click="toHandler('edit')" icon="edit" v-if="isEdit">编辑</a-button>
          <!-- <a-button v-if="selectedRowKeys.length==0||(selectedRows.length>0)" type="primary" @click="showTrackLog()" icon="eye">跟踪日志</a-button> -->
          <a-button v-if="isAudit && selectedRows.length > 0 && selectedRows[0].flowStatus == 2" type="default" @click="toHandler('rollbackStatus')" icon="rollback">撤审</a-button>
          <a-button type="default" @click="toHandler('del')" icon="delete" v-if="isDelete">删除</a-button>
          <downLoad
              v-if="isExport"
              method="get"
              api="/api/product/system/info/dealer/excelExportDealerProductList"
              :params="searchData"
              name="商品导出.xls"
          ></downLoad>
          <a-upload
            name="file"
            :action="uploadUrl"
            :headers="headers"
            :showUploadList="false"
            @change="uploadChange"
            v-if="isImport"
          >
            <a-button icon="upload" type="default">导入</a-button>
          </a-upload>
          <a-button
            v-if="
              selectedRowKeys.length > 0 ||
              (selectedRows.length > 0 && selectedRows[0].flagUp == 2 && selectedRows[0].flowStatus == 3 && isPutaway)
            "
            type="default"
            @click="onStatusChange('up')"
            icon="check-circle"
            >上架</a-button
          >
          <a-button
            v-if="
              selectedRowKeys.length > 0 ||
              (selectedRows.length > 0 && selectedRows[0].flagUp == 1 && selectedRows[0].flowStatus == 3 && isSoldOut)
            "
            type="default"
            @click="onStatusChange('down')"
            icon="stop"
            >下架</a-button
          >
        </div>
        <div v-if="pageType == 'audited'">
          <a-button type="primary" @click="toHandler('check')" icon="copy" v-if="isCheck">查看</a-button>
          <downLoad
            method="get"
            api="/api/product/system/info/excelExportDealerProductList"
            :params="searchData"
            v-if="isExport"
            name="商品导出.xls"
          ></downLoad>
          <a-button
            v-if="
              selectedRowKeys.length == 0 ||
              (selectedRows.length > 0 && selectedRows[0].flagUp == 2 && selectedRows[0].flowStatus == 3 && isPutaway)
            "
            type="default"
            @click="onStatusChange('up')"
            icon="check-circle"
            >上架</a-button
          >
        </div>
        <div v-if="pageType == 'off_shelf'">
          <a-button type="primary" @click="toHandler('check')" icon="copy" v-if="isCheck">查看</a-button>
          <downLoad
            method="get"
            api="/api/product/system/info/excelExportDealerProductList"
            :params="searchData"
            v-if="isExport"
            name="商品导出.xls"
          ></downLoad>
          <a-button
            v-if="
              selectedRowKeys.length == 0 ||
              (selectedRows.length > 0 && selectedRows[0].flagUp == 2 && selectedRows[0].flowStatus == 3 && isPutaway)
            "
            type="default"
            @click="onStatusChange('up')"
            icon="check-circle"
            >上架</a-button
          >
          <a-button type="default" @click="toHandler('del')" icon="delete" v-if="isDelete">删除</a-button>
        </div>
        <div v-if="pageType == 'pending_review'">
          <a-button type="primary" @click="toHandler('check')" icon="copy" v-if="isCheck">查看</a-button>
          <a-button
            v-if="selectedRows.length > 0 && selectedRows[0].flowStatus == 2 && isAudit"
            type="primary"
            @click="visible = true"
            icon="form"
            >审核</a-button
          >
          <downLoad
            method="get"
            api="/api/product/system/info/excelExportDealerProductList"
            :params="searchData"
            v-if="isExport"
            name="商品导出.xls"
          ></downLoad>
        </div>
        <div v-if="pageType == 'putaway'">
          <a-button type="primary" @click="toHandler('check')" icon="copy" v-if="isCheck">查看</a-button>
          <downLoad
            method="get"
            api="/api/product/system/info/excelExportDealerProductList"
            :params="searchData"
            v-if="isExport"
            name="商品导出.xls"
          ></downLoad>
          <a-button
            v-if="
              selectedRowKeys.length == 0 ||
              (selectedRows.length > 0 && selectedRows[0].flagUp == 1 && selectedRows[0].flowStatus == 3 && isSoldOut)
            "
            type="default"
            @click="onStatusChange('down')"
            icon="stop"
            >下架</a-button
          >
        </div>
        <a-row :gutter="8">
          <a-col :span="4">
            <div style="border: 1px solid #e8e8e8; background: white; height: 472px; overflow-y: auto">
              <a-tree
                :treeData="dataList"
                v-if="dataList.length > 0"
                :load-data="onLoadData"
                :expanded-keys="expandedKeys"
                @expand="onExpand"
                @select="handleSelect"
              >
              </a-tree>
            </div>
          </a-col>
          <a-col :span="20">
            <!-- 数据表格 -->
            <a-table
              :loading="tableLoading"
              :row-selection="rowSelection"
              :rowKey="(record) => record.id"
              :columns="columns"
              :data-source="tableData"
              bordered
              :pagination="page"
              @change="changeTable"
              :customRow="changeTableRow"
              :rowClassName="rowClassName"
              :scroll="{ x: 1300 }"
            >
              <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
              <span slot="name" slot-scope="text">
                <a-tooltip>
                  <template slot="title"> {{ text }} </template> {{ text }}
                </a-tooltip>
              </span>
              <span slot="imgUrl" slot-scope="item">
                <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
              </span>
              <span slot="flagUp" slot-scope="item">
                <!-- {{item == 1 ? '上架' : item == 2 ? '下架' : '强制下架'}} -->
                <a-tag v-if="item == 1" color="#67c23a">上架</a-tag>
                <a-tag v-if="item == 2" color="#909399">下架</a-tag>
                <a-tag v-if="item == 3" color="#909399">强制下架</a-tag>
              </span>
              <span slot="parentCategoryName" slot-scope="text">
                <a-tooltip>
                  <template slot="title"> {{ text }} </template> {{ text }}
                </a-tooltip>
              </span>
              <span slot="flowStatus" slot-scope="text">
                <a-tag v-if="text == 1" color="#e6a23c">未处理</a-tag>
                <a-tag v-if="text == 2" color="#409eff">待审核</a-tag>
                <a-tag v-if="text == 3" color="#67c23a">审核通过</a-tag>
                <a-tag v-if="text == 4" color="#f5222d">已拒绝</a-tag>
              </span>
              <span slot="price" slot-scope="item">{{ item | formatMoney }}</span>
              <span slot="action" slot-scope="text, row">
                <a @click="handleEdit(row)">编辑</a>
                <a-divider type="vertical" />
                <!-- <a @click="delByIds([row.id])">删除</a>
                        <a-divider type="vertical" /> -->
                <a class="ant-dropdown-link">更多<a-icon type="down" /></a>
              </span>
            </a-table>
          </a-col>
        </a-row>
      </a-row>
      <!-- 编辑表单 -->
      <ProductGoods-edit-modal ref="ProductGoodsEditModal" @reload="getData"></ProductGoods-edit-modal>
      <!-- 查看表单 -->
      <ProductGoods-info-modal ref="ProductGoodsInfoModal" @reload="getData"></ProductGoods-info-modal>
      <SelectTrackLogModal ref="SelectTrackLogModal"></SelectTrackLogModal>
      <a-modal
        title="审核"
        width="30%"
        :visible="visible"
        :confirm-loading="confirmLoading"
        :footer="null"
        @cancel="visible = false"
      >
        <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
          <a-form-model-item label="是否通过">
            <a-radio-group v-model="statusRadio" @change="onGoodStatusChange">
              <a-radio :value="3"> 通过 </a-radio>
              <a-radio :value="4"> 拒绝 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            label="审核后上架"
            v-if="selectedRows.length > 0 && selectedRows[0].flagAutoUp == false && statusRadio == 3"
          >
            <a-switch v-model="obj.flagAutoUp" checked-children="启用" un-checked-children="禁用" default-checked />
          </a-form-model-item>
          <!-- <a-form-model-item
            label="是否系统商品"
            v-if="selectedRows.length > 0 && selectedRows[0].flagAutoUp == false && statusRadio == 3"
          >
            <a-switch v-model="obj.flagSystemProduct" checked-children="是" un-checked-children="否" default-checked />
            <div>设置为系统商品后别的经销商可以下单</div>
          </a-form-model-item> -->
          <a-form-model-item label="原因">
            <a-textarea v-model="obj.rejectReason"></a-textarea>
          </a-form-model-item>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="visible = false">取消</a-button>
          <a-button type="danger" @click="onAudit('refuse')" v-if="statusRadio == 4">确定</a-button>
          <a-button type="primary" @click="onAudit('audit')" v-if="statusRadio == 3">确定</a-button>
        </div>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import { columns } from '@/views/product_goods/components/colums.js'
import ProductGoodsEditModal from '@/views/product_goods/components/ProductGoodsEditModal.vue'
import ProductGoodsInfoModal from '@/views/product_goods/components/ProductGoodsInfoModal.vue'
import { delProductGoods, rollbackStatus } from '@/views/product_goods/api/ProductGoodsApi'
import SelectTrackLogModal from '@/views/product_goods/components/SelectTrackLogModal.vue'
import { checkPermission } from '@/utils/permissions'
import {baseURL} from '@/utils/request'
const qs = require('qs')

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'productGoods',
  components: {
    ProductGoodsEditModal,
    SelectTrackLogModal,
    ProductGoodsInfoModal,
  },
  data() {
    return {
      confirmLoading: false,
      visible: false,
      obj: {
        flagAutoUp: false,
        rejectReason: '',
      },
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      autoExpandParent: true,
      expandedKeys: [0],
      dataList: [],
      categoryId: 0,
      //上传的数据
      uploadData: [],
      //文件数据
      fileList: [],
      //上传文件地址
      uploadUrl: baseURL + '/api/product/system/info/excelReader',
      //上传文件请求头
      headers: {
        'authorization-distributor': this.$store.getters.token,
      },
      isEdit: checkPermission('product:goods:edit'),
      isAdd: checkPermission('product:goods:add'),
      isDelete: checkPermission('product:goods:delete'),
      isCheck: checkPermission('product:goods:check'),
      isImport: checkPermission('product:goods:import'),
      isExport: checkPermission('product:goods:export'),
      isAudit: checkPermission('product:goods:audit'),
      isSubmit: checkPermission('product:goods:submit'),
      isPutaway: checkPermission('product:goods:putaway'),
      isSoldOut: checkPermission('product:goods:sold_out'),
      categoryList: [],
      brandList: [],
      statusRadio: 3,
    }
  },

  props: {
    flagDealer: {
      type: Boolean,
      default: false,
    },
    pageType: {
      type: String,
      default: '',
    },
    status: {
      type: Array,
      default: [],
    },
    flagUp: {
      type: Number,
      default: 0,
    },
    flagSystemProduct: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },

    //导入
    uploadChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
    },

    // 审核
    onAudit(name) {

      const _this = this
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      let query = {}
      if (name == 'sbm_audit') {
        query = {
          id: _this.selectedRows[0].id,
          flowStatus: 2,
          rejectReason: _this.obj.rejectReason,
          flagAutoUp: _this.obj.flagAutoUp,
        }
      } else if (name == 'audit') {
        query = {
          id: _this.selectedRows[0].id,
          flowStatus: 3,
          rejectReason: _this.obj.rejectReason,
          flagAutoUp: _this.obj.flagAutoUp,
        }
      } else if (name == 'refuse') {
        query = {
          id: _this.selectedRows[0].id,
          flowStatus: 4,
          rejectReason: _this.obj.rejectReason,
          flagAutoUp: _this.obj.flagAutoUp,
        }
      }
      _this.axios.post('/api/product/system/info/dealer/auditStatus', query).then((res) => {
        if (res.code == 200) {
          _this.$notification.success({ message: res.message })
          _this.getData()
          _this.obj = {}
          _this.visible = false
        } else {
          _this.$notification.error({ message: res.message })
          _this.getData()
          _this.obj = {}
        }
      })
    },

    // 查看
    onCheck(item) {
      const _this = this
      _this.$refs.ProductGoodsEditModal.setRowData(item, 'check')
    },

    /**
     * 获取表格数据
     */
    getData() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.tableLoading = true
      let obj = {
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        categoryId: this.categoryId == 0 ? '' : this.categoryId,
        flowStatusList: this.status,
        flagUp: this.flagUp != 0 ? this.flagUp : '',
        flagDealer: this.flagDealer,
        flagSystemProduct: this.flagSystemProduct == false ? '' : this.flagSystemProduct,
      }
      const postData = Object.assign(obj, this.searchData)
      this.axios
        .get(`/api/product/system/info/dealer/list?${qs.stringify(postData, { arrayFormat: 'repeat' })}`)
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
      this.axios.get('/api/product/category/parentCategoryList').then((res) => {
        this.categoryList = res.body
      })
      this.axios.get('/api/product/system/brand/list').then((res) => {
        this.brandList = res.body.records
      })
    },

    onGoodStatusChange(val) {
      this.statusRadio = val.target.value
    },

    // 修改是否上架
     onStatusChange(name) {
      const that = this
      if (that.selectedRows.length === 0) {
        that.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      let nameMessage = name == 'up' ?'上架':'下架' ;
      that.$confirm({
        title: nameMessage + '警告',
        content: '确认要' + nameMessage + '吗?',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          let num = {}
          if (name == 'up') {
            num = {
              id: that.selectedRows[0].id,
              flagUp: 1,
            }
          } else if (name == 'down') {
            num = {
              id: that.selectedRows[0].id,
              flagUp: 2,
            }
          }
          that.axios.post('/api/product/system/info/editFlagUpStatus', num).then((res) => {
            that.$notification.success({ message: res.message })
            that.getData()
          })
        },
        onCancel() {},
      })
    },

    /**
     * 筛选查询
     */
    getDataFilter() {
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },

    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },

    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },

    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },

    // 编辑
    handleEdit(row) {
      const _this = this
      _this.$refs.ProductGoodsEditModal.setRowData(row, 'edit')
    },

    // 删除
    delByIds(id) {
      const _this = this
      _this.$confirm({
        title: '删除警告',
        content: '确认要删除此条记录吗?',
        okText: '确认',
        cancelText: '取消',
        async onOk() {
          const res = await delProductGoods(_this.selectedRows[0].id)
          if (res.code === 200) {
            _this.$notification.success({ message: res.message })
            _this.getData()
          } else {
            _this.$notification.error({ message: res.message })
          }
        },
        onCancel() {},
      })
    },

    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.ProductGoodsEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          if (_this.selectedRows[0].flagUp != 2) {
            _this.$notification.info({
              message: '请先下架该商品，再进行编辑',
            })
            return
          }
          _this.$refs.ProductGoodsEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delProductGoods(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'rollbackStatus':
          _this.$confirm({
            title: '撤回警告',
            content: '确认要撤回此条记录的提交审核吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await rollbackStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'check':
          _this.$refs.ProductGoodsInfoModal.setRowData(_this.selectedRows[0], 'check')
          break
        case 'system':
          _this.$confirm({
            title: '提示',
            content: '确认要设置为系统商品吗，设置为系统商品之后别的经销商可以下单',
            okText: '确认',
            cancelText: '取消',
            onOk() {},
            onCancel() {},
          })
          break
      }
    },

    showTrackLog() {
      const _this = this
      if (_this.selectedRows.length === 0) {
        _this.$notification.info({
          message: '请选择一条记录',
        })
        return
      }
      _this.$refs.SelectTrackLogModal.show(_this.selectedRowKeys[0])
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    handleSelect(value) {
      let id = Number(value.toString())
      this.categoryId = id
      this.getData()
    },
    onLoadData(treeNode) {
      let id = treeNode.eventKey
      return new Promise(
        (resolve) => {
          this.axios.get('/api/product/category/list/' + id).then((res) => {
            treeNode.dataRef.children = res.body
            this.dataList = [...this.dataList]
          })
          resolve()
        },
        (reject) => {}
      )
    },
  },
  created() {
    this.getData()
    this.axios.get('/api/product/category/list/0').then((res) => {
      this.dataList = res.body
    })
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
